import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../layout/Layout';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import PerspectiveCustomerDialog from '../../components/PerspectiveCustomerDialog/PerspectiveCustomerDialog';
import ProductFeedbackDialog from '../../components/PerspectiveCustomerDialog/ProductFeedbackDialog';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Switch, FormControlLabel } from '@material-ui/core';
import { Event, gaCategory, gaAction } from '../../utils/analytics';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import DraggableFreqS11Chart from '../../components/Chart/DraggableFreqS11Chart';

import './styles.css';


const Demo3D = () => {
  const [antennaType, setAntennaType] = useState('Patch');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [generatedValues, setGeneratedValues] = useState(null);
  const [isAxisVisible, setIsAxisVisible] = useState(true);
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [loadingDots, setLoadingDots] = useState('');
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [camera, setCamera] = useState(null);
  const [controls, setControls] = useState(null);
  const [scene, setScene] = useState(null);
  const [group, setGroup] = useState(null);
  const [renderer, setRenderer] = useState(null);
  const [isS11GraphVisible, setIsS11GraphVisible] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const panelRef = useRef(null);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!isDialogShown && (!urlParams.has('d') || urlParams.get('d') !== '0')) {
      const dialogTimeout = setTimeout(() => {
        setIsDialogOpen(true);
        setIsDialogShown(true);
      }, 1000);
      return () => clearTimeout(dialogTimeout);
    }
  }, [isDialogShown]);

  useEffect(() => {
    const newScene = new THREE.Scene();
    newScene.background = new THREE.Color(0xffffff);
    const newCamera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const newRenderer = new THREE.WebGLRenderer({ antialias: true });
    newRenderer.setSize(window.innerWidth, window.innerHeight);
    document.querySelector('.demo-content').appendChild(newRenderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
    newScene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 2);
    directionalLight.position.set(2, 5, 0);
    directionalLight.castShadow = true;
    newScene.add(directionalLight);

    newRenderer.shadowMap.enabled = true;

    const newGroup = new THREE.Group();
    newScene.add(newGroup);

    const newControls = new OrbitControls(newCamera, newRenderer.domElement);
    newControls.enableDamping = true;
    newControls.dampingFactor = 0.05;
    newCamera.position.set(3, 3, 3);
    newCamera.lookAt(0, 0, 0);

    const animate = function () {
      requestAnimationFrame(animate);
      newControls.update();
      newRenderer.render(newScene, newCamera);
    };

    animate();

    setCamera(newCamera);
    setControls(newControls);
    setScene(newScene);
    setGroup(newGroup);
    setRenderer(newRenderer);

    return () => {
      newRenderer.dispose();
      document.querySelector('.demo-content').removeChild(newRenderer.domElement);
    };
  }, []);

  useEffect(() => {
    if (group) {
      group.clear();

      if (isAxisVisible) {
        const axesHelper = new THREE.AxesHelper(5);
        axesHelper.position.set(0, 0, 0);
        group.add(axesHelper);

        // Adding labels for the axes
        const createAxisLabel = (text, position) => {
          const loader = new FontLoader();
          loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {
            const textGeometry = new TextGeometry(text, {
              font: font,
              size: 0.15,
              height: 0.01,
            });
            const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);
            textMesh.position.set(position.x, position.y, position.z);
            group.add(textMesh);
          });
        };

        createAxisLabel('X', { x: 5.1, y: 0, z: 0 });
        createAxisLabel('Y', { x: 0, y: 5.1, z: 0 });
        createAxisLabel('Z', { x: 0, y: 0, z: 5.1 });

      }

      if (generatedValues) {
        const l = generatedValues.l * 10;
        const w = generatedValues.w * 10;
        const h = generatedValues.h * 10;
        const l1 = generatedValues.l1 * 10;
        const w1 = generatedValues.w1 * 10;
        const l2 = generatedValues.l2 * 10;
        const w2 = generatedValues.w2 * 10;
        const hm = 0.0001;

        const geometry = new THREE.BoxGeometry(w1, h, l1);
        const material = new THREE.MeshStandardMaterial({ color: 0x3ab284 });
        const cube = new THREE.Mesh(geometry, material);
        cube.castShadow = true;
        cube.receiveShadow = true;
        group.add(cube);

        const rectangle1Geometry = new THREE.BoxGeometry(w, hm, l);
        const rectangle1Material = new THREE.MeshStandardMaterial({ color: 0xffff8b });
        const rectangle1 = new THREE.Mesh(rectangle1Geometry, rectangle1Material);
        rectangle1.position.set(0, h / 2 + hm / 2, 0);
        rectangle1.castShadow = true;
        rectangle1.receiveShadow = true;
        group.add(rectangle1);

        const rectangle2Geometry = new THREE.BoxGeometry(w2, hm, l1 / 2);
        const rectangle2Material = new THREE.MeshStandardMaterial({ color: 0xffdc8b });
        const rectangle2 = new THREE.Mesh(rectangle2Geometry, rectangle2Material);
        rectangle2.position.set(0, h / 2 + hm / 2, l1 / 4);
        rectangle2.castShadow = true;
        rectangle2.receiveShadow = true;
        group.add(rectangle2);

        const bottomRectangleGeometry = new THREE.BoxGeometry(w1, hm, l1);
        const bottomRectangleMaterial = new THREE.MeshStandardMaterial({ color: 0xffff8b });
        const bottomRectangle = new THREE.Mesh(bottomRectangleGeometry, bottomRectangleMaterial);
        bottomRectangle.position.set(0, -h / 2 - hm / 2, 0);
        bottomRectangle.castShadow = true;
        bottomRectangle.receiveShadow = true;
        group.add(bottomRectangle);
      }
    }
  }, [isAxisVisible, generatedValues, group]);

  useEffect(() => {
    let dotsInterval;
    if (generatedValues === 'generating...') {
      dotsInterval = setInterval(() => {
        setLoadingDots((prev) => (prev.length < 6 ? prev + ' .' : ''));
      }, 500);
    }
    return () => clearInterval(dotsInterval);
  }, [generatedValues]);

  const handleAntennaTypeChange = (event) => {
    setAntennaType(event.target.value);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleGenerateClick = () => {
    Event({ category: gaCategory.DEMO, action: gaAction.DEMO_GENERATE_BUTTON });
    setGeneratedValues('generating...');
    setTimeout(() => {
      setGeneratedValues({
        eps: 4.015594,
        l: 0.021004,
        w: 0.033412,
        h: 0.010881,
        l1: 0.050574,
        w1: 0.06209,
        l2: 0.00639,
        w2: 0.006933,
        w3: 0.004838,
        frequency: [10.684141512205107, 10.7980152562951, 11.583102352238974, 11.816773740118197, 12.029313281935181],
        s11: [-14.509099775000246, -14.48223765302709, -14.61214811067421, -14.492081881194341, -14.475711789849212]
      });
    }, 5000);
  };

  const handleAxisToggle = (event) => {
    setIsAxisVisible(event.target.checked);
  };

  const handleFeedbackClick = () => {
    setIsFeedbackDialogOpen(true);
  };

  const handleFeedbackDialogClose = () => {
    setIsFeedbackDialogOpen(false);
  };

  const handleZoomIn = () => {
    if (camera) {
      camera.position.set(camera.position.x * 0.9, camera.position.y * 0.9, camera.position.z * 0.9);
    }
  };

  const handleZoomOut = () => {
    if (camera) {
      camera.position.set(camera.position.x / 0.9, camera.position.y / 0.9, camera.position.z / 0.9);
    }
  };

  useEffect(() => {
    if (panelRef.current) {
      // Check if the content overflows
      setIsOverflowing(panelRef.current.scrollHeight > panelRef.current.clientHeight);
    }
  }, [generatedValues]); // Run after the content changes

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setIsAtBottom(scrollTop + clientHeight >= scrollHeight);
  };


  return (
    <Layout>
      <div className="demo-page">

        {generatedValues !== 'generating...' && generatedValues && isS11GraphVisible && (
          <DraggableFreqS11Chart xValues={generatedValues.frequency} yValues={generatedValues.s11} />
        )}


        <PerspectiveCustomerDialog open={isDialogOpen} onClose={handleDialogClose} disableBackdropClick={true} disableEscapeKeyDown={true} />
        <ProductFeedbackDialog open={isFeedbackDialogOpen} onClose={handleFeedbackDialogClose} />


        <div className="floating-panel-wrapper">
          <div
            className="floating-panel"
            ref={panelRef}
            onScroll={handleScroll}
            style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}
          >
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="antenna-type-label" shrink>Antenna Type</InputLabel>
                    <Select
                      labelId="antenna-type-label"
                      id="antenna-type"
                      value={antennaType}
                      onChange={handleAntennaTypeChange}
                      label="Antenna Type"
                    >
                      <MenuItem value="Patch">Patch</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="frequency-low"
                    label="Frequency Min (GHz)"
                    type="number"
                    value="9"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="frequency-high"
                    label="Frequency Max (GHz)"
                    type="number"
                    value="14"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="s11threshold"
                    label="S11 Threshold"
                    type="number"
                    value="-10"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="showAxesToggle"
                        checked={isAxisVisible}
                        onChange={handleAxisToggle}
                        name="axisToggle"
                        color="primary"
                      />
                    }
                    label="Show Axes"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        id="showS11GraphToggle"
                        checked={isS11GraphVisible}
                        onChange={(event) => setIsS11GraphVisible(event.target.checked)}
                        name="s11GraphToggle"
                        color="primary"
                      />
                    }
                    label="Show S11 graph"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: '#ff9800', color: '#ffffff', borderRadius: '50px', marginTop: '16px', textTransform: 'none' }}
                    fullWidth
                    onClick={handleGenerateClick}
                  >
                    Generate
                  </Button>
                </Grid>
                {generatedValues === 'generating...' && (
                  <Grid item xs={12}>
                    <div className="generated-values">
                      <p>generating{loadingDots}</p>
                    </div>
                  </Grid>
                )}
                {generatedValues !== 'generating...' && generatedValues && (
                  <Grid item xs={12}>
                    <div className="generated-values" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}>
                      <p>eps: {generatedValues.eps}</p>
                      <p>l (m): {generatedValues.l}</p>
                      <p>w (m): {generatedValues.w}</p>
                      <p>h (m): {generatedValues.h}</p>
                      <p>l1 (m): {generatedValues.l1}</p>
                      <p>w1 (m): {generatedValues.w1}</p>
                      <p>l2 (m): {generatedValues.l2}</p>
                      <p>w2 (m): {generatedValues.w2}</p>
                      <p>w3 (m): {generatedValues.w3}</p>
                    </div>
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
          {isOverflowing && !isAtBottom && <div className="gradient-fade"></div>}
        </div>

        <div className="demo-content"></div>

        <div
          style={{
            position: 'fixed',
            top: '50%',
            right: '20px',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#f0f0f0',
            borderRadius: '50px',
            padding: '2px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Button
            className="zoom-control-btn"
            onClick={handleZoomIn}
            style={{ marginBottom: '10px' }}
          >
            <AddIcon />
          </Button>
          <Button
            className="zoom-control-btn"
            onClick={handleZoomOut}
          >
            <RemoveIcon />
          </Button>
        </div>

        <Button
          variant="contained"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#4caf50',
            color: '#ffffff',
            borderRadius: '50px',
            textTransform: 'none',
          }}
          onClick={handleFeedbackClick}
        >
          Feedback
        </Button>
      </div>
    </Layout>
  );
};



export default Demo3D;
