import React, { useRef, useState, useEffect } from 'react';
import * as d3 from 'd3';

import './styles.css';

const DraggableFreqS11Chart = ({ xValues, yValues }) => {
    const containerRef = useRef(null);
    const chartRef = useRef(null);
    const [position, setPosition] = useState({ top: 100, left: 100 });
  
    useEffect(() => {
      if (xValues && yValues) {
        drawChart();
      }
    }, [xValues, yValues]);
  
    const handleMouseDown = (e) => {
      const container = containerRef.current;
      const shiftX = e.clientX - container.getBoundingClientRect().left;
      const shiftY = e.clientY - container.getBoundingClientRect().top;
  
      const handleMouseMove = (moveEvent) => {
        setPosition({
          top: moveEvent.clientY - shiftY,
          left: moveEvent.clientX - shiftX,
        });
      };
  
      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };
  
    const drawChart = () => {
      const svgWidth = 500;
      const svgHeight = 300;
      const margin = { top: 20, right: 30, bottom: 50, left: 60 };
  
      // Clear the previous chart
      d3.select(chartRef.current).selectAll("*").remove();
  
      // Create the SVG element
      const svg = d3
        .select(chartRef.current)
        .attr("width", svgWidth)
        .attr("height", svgHeight)
        .append("g")
        .attr(
          "transform",
          `translate(${margin.left}, ${margin.top})`
        );
  
      const width = svgWidth - margin.left - margin.right;
      const height = svgHeight - margin.top - margin.bottom;
  
      // Set the scales
      const xScale = d3.scaleLinear()
        .domain(d3.extent(xValues))
        .range([0, width]);
  
      const yScale = d3.scaleLinear()
        .domain([d3.min(yValues), d3.max(yValues)])
        .range([height, 0]);
  
      // Create the line generator
      const line = d3.line()
        .x((d, i) => xScale(xValues[i]))
        .y((d, i) => yScale(yValues[i]))
        .curve(d3.curveMonotoneX); // Smooth line
  
      // Draw the x-axis
      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(xScale))
        .append("text")
        .attr("fill", "#000")
        .attr("x", width / 2)
        .attr("y", 40)
        .attr("font-size", "16px")
        .attr("text-anchor", "middle")
        .text("freq (GHz)");
  
      // Draw the y-axis
      svg.append("g")
        .call(d3.axisLeft(yScale))
        .append("text")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("x", -height / 2)
        .attr("y", -50)
        .attr("font-size", "16px")
        .attr("text-anchor", "middle")
        .text("S11 (dB)");
  
      // Draw the line
      svg.append("path")
        .datum(yValues)
        .attr("fill", "none")
        .attr("stroke", "#3498db")
        .attr("stroke-width", 2)
        .attr("d", line);
    };
  
    return (
      <div
        className="chart-container"
        ref={containerRef}
        style={{ top: position.top, left: position.left }}
        onMouseDown={handleMouseDown}
      >
        <svg ref={chartRef}></svg>
      </div>
    );
  };

export default DraggableFreqS11Chart;
